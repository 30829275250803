import {defineStore} from "pinia";
import type {Ref} from "vue";
import {computed, ref} from "vue";
import type {FormConfiguration} from "~/types";

export const useRootStore = defineStore('root', () => {
    //state
    const dataServer = ref(null)
    const config: Ref<FormConfiguration> = ref<FormConfiguration>(null)
    const query = ref(null);
    //Actions
    const setConfig = (value: FormConfiguration): void => {
        config.value = value
    }

    const setQuery = (value) => {
        query.value = value
    }
    const setDataServer = (value) => {
        dataServer.value = value
    }

    const isConfigLoaded = () => config.value !== null;

    //getters
    const getDataServer = computed(() => {
        return dataServer.value
    })
    const getConfig = computed(() => {
        return config
    })
    const getQuery = computed(() => {
        return query.value
    })


    return {
        dataServer,
        config,
        query,
        setConfig,
        isConfigLoaded,
        setQuery,
        setDataServer,
        getDataServer,
        getConfig,
        getQuery,
    }
})